<template>
	<div class="home">
		<div id="Home" data-section-index="0" class="container">
			<!--<Intro v-if="$api.texts.homepage_intro"/>-->
      <router-link :to="{ name: 'Home' }" class="back-to-top" ref="back-to-top" @click.native="backToTop">
        <Icon src="caret-up"/>
      </router-link>
		</div>
    <!-- Don't forget to edit meta variable in data() as well!!! -->
    <ProductsOverview id="ProductsShots" data-section-index="1" type="shots" :visible="productSectionsVisible.shots" @toggle-section="toggleProductSectionVisibility('shots')" />
    <div id="ProductsShotsOpen" data-section-index="2" class="homepage-products-section" :class="{ hidden: !productSectionsVisible.shots }">
      <div class="container">
        <OtherProducts :count="9" :syrup="false" :syrup-exclude="true"/>
      </div>
      <WhyLinear />
      <HowToUse />
      <TryOut />
    </div>
    <ProductsOverview id="ProductsSyrups" data-section-index="3" type="syrups" :visible="productSectionsVisible.syrups" @toggle-section="toggleProductSectionVisibility('syrups')" />
    <div id="ProductsSyrupsOpen" data-section-index="4" class="homepage-products-section bg-orange" :class="{ hidden: !productSectionsVisible.syrups }">
      <div class="container ">
        <OtherProducts :count="9" :syrup="true" :syrup-exclude="true"/>
      </div>
    </div>
    <div id="Vouchers" data-section-index="5">
      <Vouchers/>
    </div>
    <div id="Story" data-section-index="6" class="fold-bg-after fold-bg-after-white">
      <Story v-if="$api.texts.story_p1"/>
    </div>
    <div id="Media" data-section-index="7">
      <MediaAppearences />
    </div>
    <div id="WhereToBuy" data-section-index="8">
      <WhereToBuy />
    </div>
    <!--
    <div id="Recipes" data-section-index="8">
      <Recipes />
    </div>
    -->
	</div>
</template>

<script>
	import {EventBus} from "../../event-bus";
	import Story from "./Homepage/Story";
	import OtherProducts from "../components/OtherProducts";
	//import Instagram from "./Homepage/Instagram";
	import Vouchers from "./Homepage/Vouchers";
  import MediaAppearences from "./Homepage/MediaAppearances";
  import WhyLinear from "./Homepage/WhyLinear";
  import HowToUse from "./Homepage/HowToUse";
  import TryOut from "./Homepage/TryOut";
  //import Recipes from "./Homepage/Recipes";
  import WhereToBuy from "./Homepage/WhereToBuy";
  import ProductsOverview from '@/views/pages/Homepage/ProductsOverview.vue';
  import Icon from '@/views/components/Icon.vue';

	export default {
		name: "Home",
		metaInfo() {
			return {
				title: this.meta[this.currentSection].title,
				meta: [{
					name: "description",
					content: this.meta[this.currentSection].description,
				}]
			};
		},
		components: {
      Icon,
      ProductsOverview,
      WhereToBuy,
      // Recipes,
      TryOut,
      HowToUse, WhyLinear, MediaAppearences, Vouchers, /*Instagram,*/ OtherProducts, Story, /*Ingredients, NewsSlider, Intro*/},
		mounted() {
			this.sections = document.querySelectorAll("[data-section-index]");
			EventBus.$emit("changeTheme", "white");
			EventBus.$emit("loading", true);

			if (this.$route.name === "Home")
				EventBus.$emit("scrollingToTop");

			this.$nextTick(() => {
				// Timeout is necessary for scroll to work properly
				setTimeout(() => {
					// Scroll page to section from route
					this.scrollToSection(this.$route.name, false);
					this.initObservers();
					EventBus.$emit("loading", false);
				}, 200);
			});
		},
		destroyed() {
			this.destroyObservers();
		},
		data() {
			return {
				sectionObserver: null,
				currentSection: 0,
        // Same as /src/views/components/Footer.vue:2 [data-section-index]; don't forget to edit meta array below
        footerSectionId: 9,
				visibleSections: [],
				scrollingTo: null,
				disableRouteScroll: false,
				intersectionObserverSupported: 'IntersectionObserver' in window,
				meta: {
					0: {
						title: "Ginger shoty & sirupy",
						description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
					},
					1: {
						title: "Produkty",
						description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
					},
          2: {
            title: "Produkty",
            description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
          },
          3: {
            title: "Produkty",
            description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
          },
          4: {
            title: "Produkty",
            description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
          },
					/*5: {
						title: "Proč užívat",
						description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
					},
					6: {
						title: "Jak užívat",
            description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
					},*/
          5: {
            title: "Vouchery",
            description: "Potěšte své blízké dárkovým poukazem. Obdarovaný si vybere podle svého gusta a balík s ginger shoty mu dorazí čerstvý, zrovna když bude mít chuť, nebo potřebu doplnit vitamíny. Poukaz lze uplatnit pro všechny produkty na webu www.koju.cz a to nejpozději do data uvedeném na něm. Platnost voucheru je 12 měsíců od zakoupení. Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
          },
          6: {
            title: "Náš příběh",
            description: "Jsme dva kamarádi - Petr a Roman, bývalý spolužáci ze základní i vysoké školy, hokejoví spoluhráči, fitness nadšenci a milovníci dobré kávy. Společného tedy máme opravdu dost. A aby toho nebylo málo, rozhodli jsme se společně i podnikat. Jelikož jsme oba sportovně zaměření a snažíme se žít zdravým životním stylem, chceme tyto vlastnosti přenášet i do našich produktů. Myšlenka vyrobit si vlastní ginger shoty nás ohromně nadchla, protože spojuje obě vlastnosti dohromady. Začali jsme tedy zkoušet, testovat a experimentovat."
          },
          7: {
            title: "Napsali o nás",
            description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
          },
          8: {
            title: "Kde nás najdete",
            description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
          },
          /*8: {
            title: "Namíchej si",
            description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
          },*/
					9: {
						title: "Kontakt",
						description: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie."
					},
				},
        productSectionsVisible: {
          shots: true,
          syrups: true,
        },
			}
		},
		watch: {
			$route(to) {
				// Route change triggered programatically -> don't scroll to section from route
				if (this.disableRouteScroll)
					this.disableRouteScroll = false;
				// User clicked on a link -> scroll to given section
				else
					this.scrollToSection(to.name);
			}
		},
		methods: {
			scrollToSection(id, smooth = true, padding = 70) {
				// Disable scrolling observer until this smooth scroll is finished
				if (smooth)
					this.scrollingTo = id;

				const element = document.getElementById(id);
				if (id !== "Home" && !element) return;

				return window.scrollTo({
					top: id === "Home" ? 0 : element.offsetTop - padding,
					left: 0,
					behavior: smooth ? "smooth" : "auto"
				})
			},

			destroyObservers() {
        window.removeEventListener("scroll", this.toggleBackToTopButton);

				if (this.intersectionObserverSupported) {
					if (this.sectionObserver)
						this.sectionObserver.disconnect();
				} else
					window.removeEventListener("scroll", this.sectionObserverFallback);
			},

			initObservers() {
				this.destroyObservers();

        // Scroll observer for back-to-top button
        window.addEventListener("scroll", this.toggleBackToTopButton, { passive: true });

        // Scroll section observer
				if (this.intersectionObserverSupported) {
					const options = {
						rootMargin: '-90px 0px',
						threshold: 0
					};

					this.sectionObserver = new IntersectionObserver(this.sectionObserverHandler, options);

					// Observe each section
					this.sections.forEach(section => {
						this.sectionObserver.observe(section);
					});
				} else {
					this.sectionObserver = window.pageYOffset;
					window.addEventListener("scroll", this.sectionObserverFallback, { passive: true });
				}
			},

      toggleBackToTopButton() {
        this.$refs["back-to-top"].$el.classList.toggle("visible", window.scrollY > window.outerHeight);
      },

      backToTop() {
        this.scrollToSection("Home");
      },

			// Get indexes of all visible sections (through IntersectionObserver)
			sectionObserverHandler(entries) {
				for (const entry of entries) {
					let sectionIndex = parseInt(entry.target.dataset.sectionIndex);
					let isVisible = entry.isIntersecting;

					if (isVisible)
						this.visibleSections.push(sectionIndex);
					else {
						let arrayIndex = this.visibleSections.indexOf(sectionIndex);
						if (arrayIndex > -1)
							this.visibleSections.splice(arrayIndex, 1);
					}
				}

				this.setActiveVisibleSection(entries.length > 3);
			},

			// Get indexes of all visible sections (through scroll event, IntersectionObserver fallback)
			sectionObserverFallback() {
				let currentScroll = window.scrollY;

				// Scrolled less than 40 px since last event
				if (Math.abs(currentScroll - this.sectionObserver) < 40 && !this.scrollingTo)
					return;

				this.sectionObserver = currentScroll;

				let sectionCount = this.sections.length;
				this.visibleSections = [];

				for (let i = sectionCount-1; i >= 0; i--) {
					let section = this.sections[i];
					let isVisible =
						section.offsetTop + section.offsetHeight - 90 > currentScroll &&
						section.offsetTop - 90 < currentScroll + window.innerHeight;

					if (isVisible)
						this.visibleSections.push(parseInt(section.dataset.sectionIndex));
				}

				this.setActiveVisibleSection();
			},

			// this.visibleSections contains indexes of visible sections -> set correct one as active
			setActiveVisibleSection(justInitialized = false) {
				this.visibleSections = this.visibleSections.sort();

				// If contacts are visible, set them as active; otherwise activate first section
				let activeSectionIndex = (this.visibleSections.includes(this.footerSectionId)) ? this.footerSectionId : this.visibleSections[0];

				if (activeSectionIndex !== this.currentSection) {
					let el = this.sections[activeSectionIndex];
					if (el) {
						let sectionName = el.id;

						// Smooth scroll is in progress -> don't trigger route change
						if (this.scrollingTo && this.scrollingTo !== sectionName)
							return;

						this.currentSection = activeSectionIndex;
						this.scrollingTo = null;

						if (this.$route.name !== sectionName && !justInitialized) {
							this.disableRouteScroll = true;
							this.$router.push({name: sectionName});
						}
					}
				}
			},

      toggleProductSectionVisibility(section) {
        this.productSectionsVisible[section] = !this.productSectionsVisible[section];

        if (this.productSectionsVisible[section])
          this.$router.push({name: `Products${section.charAt(0).toUpperCase()}${section.slice(1)}Open`});
      }
		}
	};
</script>
